import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  // split,
} from '@apollo/client/core'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { computed } from '@vue/composition-api'
// import { getMainDefinition } from '@apollo/client/utilities'
const httpURL = process.env.VUE_APP_HASURA_HTTP_URL
const wsURL = process.env.VUE_APP_HASURA_WS_URL

export default function useApollo() {
  // Instantiate client

  return {
    http: computed(() => new ApolloClient({
      link: createHttpLink({
        uri: httpURL,
        headers: {
          authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      }),
      cache: new InMemoryCache({
        // eslint-disable-next-line no-underscore-dangle
        dataIdFromObject: o => (o._id ? `${o.__typename}:${o._id}` : null),
      }),
    })),
    sock: computed(() => new SubscriptionClient(wsURL, {
      reconnect: true,
      lazy: true,
      connectionParams: {
        headers: {
          authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      },
    })),
  }
}
